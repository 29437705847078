<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="promos-banner-container space-top w-full">
            <div class="relative h-full w-full flex justify-center flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5 lg:mr-8" src="../../../assets/images/home-page/promos/promos-banner.png" alt="Promos Banner">
                <div class="flex flex-col -mb-4 mobileL:mb-0 z-10 max-w-xs md:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-4 text-start mobileL:text-center ml-0 lg:ml-16 2xl:ml-20">
                    <h1 class="promo-banner-title flex justify-start mobileL:justify-center mobileS:text-2xl xs:text-3xl md:text-5xl lg:text-6xl ml-6 mobileL:ml-0 mb-2 mobileL:mb-0 text-white-1 leading-snug">
                        {{ titleText }}
                    </h1>
                    <p class="mobileL:mt-4 text-base md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl ml-1 px-5 mr-6 mobileL:mr-0 text-white-1 leading-snug mobileL:leading-normal">
                        We love offering special (and even exclusive) promos from
                        time-to-time! Scroll down to know the best deals and 
                        discounts you can snag NOW!
                    </p>
                </div>
            </div>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/home-page/promos/banner-2.png" alt="Background Image">
            <div class="relative flex items-center justify-center mobileL:gap-12">
                <img class="mobileL:mr-4 w-2/5 mobileL:w-1/2 my-12" src="../../../assets/images/home-page/promos/ribbon.png" alt="Ribbon">
                <p class="text-brown-4 w-2/3 mobileL:w-1/3 text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl text-center mobileL:my-0 my-8">
                    We love offering special (and even exclusive) promos from time-to-time! 
                    Get a FREE eyebrow threading and a 15% off when you avail any of our waxing 
                    and threading services on your birth month!
                </p>
            </div>
        </div>
        <div class="bg-brown-4 py-6 mobileL:py-12 text-white-1">
            <div class="flex flex-col items-center justify-center text-center">
                <h2 class="font-tahu text-4xl md:text-6xl lg:text-7xl 2xl:text-9xl">Frequency Card Reward</h2>
                <p class="mobileL:w-4/6 mobileL:mt-8 mx-4 mobileL:mx-0 text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl">
                    For a minimum spend of P300.00 per visit; we'll give you a sticker, 
                    a stamp and a signature on each frequency card box to verify your visit. 
                    You'll get P100.00 OFF on your 5th visit and P200.00 OFF on your 10th visit
                </p>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybareFooter.vue'

export default {
    components: {
            Header,
            Footer,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        titleText() {
            return this.windowWidth < 480 ? "Promo" : "Promos and Discounts";
        },
    },
    mounted() {
        this.$gtag.pageview('/laybare/promos'),
        window.addEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
}
</script>

<style>
.promos-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #F2D0B7 0%, #D9AC8D 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .promos-banner-container {
        height: 45dvh;
    }
}
.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .promos-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .promos-banner-container{
        height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.promo-banner-title {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 0px #C58965;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    .promo-banner-title {
        text-shadow: 0px 6px 0px #C58965;
    }
}

@media only screen and (min-width: 992px) {
    .promo-banner-title {
        text-shadow: 0px 8px 0px #C58965;
    }
}

@media only screen and (min-width: 1200px) {
    .promo-banner-title {
        text-shadow: 0px 10px 0px #C58965;
    }
}

.birtday-discount-banner-container {
    background: url('../../../assets/images/home-page/promos/discount.jpg')
        no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.frequency-card-banner-container {
    background: url('../../../assets/images/home-page/promos/frequency.jpg')
        no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>
